<template>
  <div class="home">
    <div class="container">
    	<div class="row">
			<div class="col-md-2"></div>
			<div class="col-md-8">
				<img src="@/assets/img/logo-aniversario.svg">
				<h1>25 años de economía circular</h1>
				<p>Descubre los hitos de nuestra historia y demuestra tu conocimiento.</p>
				<div class="d-flex justify-content-center flex-column flex-md-row justify-items-center mt-5">
					<router-link to="/historia"><button class="white mt-3 mx-md-2">Descubrir camino</button></router-link>
					<router-link to="/quiz"><button class="empty mt-3 mx-md-2">Quiz 25 años</button></router-link>
					<router-view/>

				</div>
			</div>
			<div class="col-md-2"></div>
    	</div>
	</div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  
}
</script>

<style scoped>
	.home {
		background-color: #02AB69;
		color: #fff;
		display: flex;
		align-items: center;
		min-height: 100vh;
		width: 100%;
	}

	img {
		margin-bottom: 40px;
		width: 320px;
	}

	h1 {
		font-size: 40px;
		font-weight: bold;
	}

	p {
		font-size: 28px;
		font-weight: 400;
		line-height: 1.25;
		margin-right: 100px;
		margin-left: 100px;
	}

	button {
		padding: 20px 25px;
		border: 4px solid #fff  
	}

	button.white {
		background-color: #fff;
		color: #02AB69
	}

	button.empty {
		background-color: transparent;
		color: #fff
	}

	@media (max-width: 600px) {
		img {
			width: 50%;
			margin-bottom: 20px;
		}

		h1 {
			font-weight: 28px;
		}

		p {
			margin: 0;
			font-size: 18px;
		}

		button {
			width: 100%;
		}
	}

</style>
