import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/historia",
		name: "Historia",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../views/Historia.vue"),
	},
	{
		path: "/quiz",
		name: "Quiz",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import("../views/Quiz.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
